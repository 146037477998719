<template>
  <v-container id="dashboard" fluid tag="section" v-if="dashboardData.users">
    <v-row>
      <v-col>
        <base-material-stats-card
          icon="mdi-account-group"
          color="cyan"
          class="dashboard-user"
          :title="$t('user.Total Users')"
          :value="dashboardData.users.total.toString()"
        />
      </v-col>

      <v-col>
        <base-material-stats-card
          class="dashboard-user"
          color="#FF00FF"
          icon="mdi-account-group"
          :title="$t('user.Users last month')"
          :value="dashboardData.users.last_month.toString()"
        />
      </v-col>
      <v-col>
        <base-material-stats-card
          class="dashboard-user"
          color="orange"
          icon="mdi-account-group"
          :title="$t('user.Users last 24h')"
          :value="dashboardData.users.last_24h.toString()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" sm="6">
        <base-material-card
          color="cyan"
          class="dashboard-transactions"
          icon="mdi-bank-transfer"
          :title="$t('transactions.Total transactions')"
        >
          <v-card-text>
            <div
              v-for="item in dashboardData.transactions.total"
              :key="item.name"
              class="row"
            >
              <div class="text-left col col-sm-4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="red" dark v-bind="attrs" v-on="on"
                      >mdi-arrow-up</v-icon
                    >
                  </template>
                  <span>{{ $t("transactions.send") }}</span>
                </v-tooltip>
                {{ item.cdebit }}:
              </div>
              <div class="text-right col col-sm-8">
                {{ item.debit + " " + item.currency }}
              </div>
              <div class="text-left col col-sm-4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on"
                      >mdi-arrow-down</v-icon
                    >
                  </template>
                  <span>{{ $t("transactions.receive") }}</span>
                </v-tooltip>
                {{ item.ccredit }}:
              </div>
              <div class="text-right col col-sm-8">
                {{ item.credit + " " + item.currency }}
              </div>
            </div>
            <br />
            <div
              v-for="(item, index) in dashboardData.fees.total"
              :key="'f' + index"
              class="row"
            >
              <div class="text-left col col-sm-4">
                {{ $t("transactions.fees") }}:
              </div>
              <div class="text-right col col-sm-8">
                {{ item.debit + " " + item.currency }}
              </div>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <base-material-card
          color="#FF00FF"
          class="dashboard-transactions"
          icon="mdi-bank-transfer"
          :title="$t('transactions.Transactions last month')"
        >
          <v-card-text>
            <div
              v-for="item in dashboardData.transactions.last_month"
              :key="item.name"
              class="row"
            >
              <div class="text-left col col-sm-4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="red" dark v-bind="attrs" v-on="on"
                      >mdi-arrow-up</v-icon
                    >
                  </template>
                  <span>{{ $t("transactions.send") }}</span>
                </v-tooltip>
                {{ item.cdebit }}:
              </div>
              <div class="text-right col col-sm-8">
                {{ item.debit + " " + item.currency }}
              </div>
              <div class="text-left col col-sm-4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on"
                      >mdi-arrow-down</v-icon
                    >
                  </template>
                  <span>{{ $t("transactions.receive") }}</span>
                </v-tooltip>
                {{ item.ccredit }}:
              </div>
              <div class="text-right col col-sm-8">
                {{ item.credit + " " + item.currency }}
              </div>
            </div>
            <br />
            <div
              v-for="(item, index) in dashboardData.fees.last_month"
              :key="'f' + index"
              class="row"
            >
              <div class="text-left col col-sm-4">
                {{ $t("transactions.fees") }}:
              </div>
              <div class="text-right col col-sm-8">
                {{ item.debit + " " + item.currency }}
              </div>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col cols="12" md="4" sm="6">
        <base-material-card
          color="orange"
          class="dashboard-transactions"
          icon="mdi-bank-transfer"
          :title="$t('transactions.Transactions last 24h')"
        >
          <v-card-text>
            <div
              v-for="item in dashboardData.transactions.last_24h"
              :key="item.name"
              class="row"
            >
              <div class="text-left col col-sm-4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="red" dark v-bind="attrs" v-on="on"
                      >mdi-arrow-up</v-icon
                    >
                  </template>
                  <span>{{ $t("transactions.send") }}</span>
                </v-tooltip>
                {{ item.cdebit }}:
              </div>
              <div class="text-right col col-sm-8">
                {{ item.debit + " " + item.currency }}
              </div>
              <div class="text-left col col-sm-4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on"
                      >mdi-arrow-down</v-icon
                    >
                  </template>
                  <span>{{ $t("transactions.receive") }}</span>
                </v-tooltip>
                {{ item.ccredit }}:
              </div>
              <div class="text-right col col-sm-8">
                {{ item.credit + " " + item.currency }}
              </div>
            </div>
            <br />
            <div
              v-for="(item, index) in dashboardData.fees.last_24h"
              :key="'f' + index"
              class="row"
            >
              <div class="text-left col col-sm-4">
                {{ $t("transactions.fees") }}:
              </div>
              <div class="text-right col col-sm-8">
                {{ item.debit + " " + item.currency }}
              </div>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <base-material-card
          color="cyan"
          icon="mdi-scale-balance"
          class="dashboard-balance"
          :title="$t('user.Total Balances')"
        >
          <v-card-text>
            <div
              v-for="(item, index) in dashboardData.balances"
              :key="index"
              class="row"
            >
              <div class="text-left col col-sm-4">{{ index }}:</div>
              <div class="text-right col col-sm-8">{{ item }}</div>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import crudMixin from "mixins/crudTable";
import crudMixin2 from "mixins/crudForm";
export default {
  mixins: [crudMixin, crudMixin2],
  name: "DashboardDashboard",
  data() {
    return {
      apiQuery: "api/dashboard",
      dashboardData: [],
    };
  },
  created() {
    this.get("/dashboard").then((data) => {
      this.dashboardData = data;
    });
  },

  methods: {},
};
</script>
<style scoped>
.break-style {
  word-break: break-word;
  max-width: 7.5rem;
}
.dashboard-user {
  margin-top: -10px;
  height: 5rem;
}
.dashboard-transactions {
  min-height: 13rem;
}
.dashboard-balance {
  min-height: 13rem;
  width: 20rem;
}
li {
  font-size: 12px;
  list-style: none;
}
.nobr {
  white-space: nowrap;
}
</style>
