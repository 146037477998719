var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dashboardData.users)?_c('v-container',{attrs:{"id":"dashboard","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',[_c('base-material-stats-card',{staticClass:"dashboard-user",attrs:{"icon":"mdi-account-group","color":"cyan","title":_vm.$t('user.Total Users'),"value":_vm.dashboardData.users.total.toString()}})],1),_c('v-col',[_c('base-material-stats-card',{staticClass:"dashboard-user",attrs:{"color":"#FF00FF","icon":"mdi-account-group","title":_vm.$t('user.Users last month'),"value":_vm.dashboardData.users.last_month.toString()}})],1),_c('v-col',[_c('base-material-stats-card',{staticClass:"dashboard-user",attrs:{"color":"orange","icon":"mdi-account-group","title":_vm.$t('user.Users last 24h'),"value":_vm.dashboardData.users.last_24h.toString()}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('base-material-card',{staticClass:"dashboard-transactions",attrs:{"color":"cyan","icon":"mdi-bank-transfer","title":_vm.$t('transactions.Total transactions')}},[_c('v-card-text',[_vm._l((_vm.dashboardData.transactions.total),function(item){return _c('div',{key:item.name,staticClass:"row"},[_c('div',{staticClass:"text-left col col-sm-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-up")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("transactions.send")))])]),_vm._v(" "+_vm._s(item.cdebit)+": ")],1),_c('div',{staticClass:"text-right col col-sm-8"},[_vm._v(" "+_vm._s(item.debit + " " + item.currency)+" ")]),_c('div',{staticClass:"text-left col col-sm-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-down")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("transactions.receive")))])]),_vm._v(" "+_vm._s(item.ccredit)+": ")],1),_c('div',{staticClass:"text-right col col-sm-8"},[_vm._v(" "+_vm._s(item.credit + " " + item.currency)+" ")])])}),_c('br'),_vm._l((_vm.dashboardData.fees.total),function(item,index){return _c('div',{key:'f' + index,staticClass:"row"},[_c('div',{staticClass:"text-left col col-sm-4"},[_vm._v(" "+_vm._s(_vm.$t("transactions.fees"))+": ")]),_c('div',{staticClass:"text-right col col-sm-8"},[_vm._v(" "+_vm._s(item.debit + " " + item.currency)+" ")])])})],2)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('base-material-card',{staticClass:"dashboard-transactions",attrs:{"color":"#FF00FF","icon":"mdi-bank-transfer","title":_vm.$t('transactions.Transactions last month')}},[_c('v-card-text',[_vm._l((_vm.dashboardData.transactions.last_month),function(item){return _c('div',{key:item.name,staticClass:"row"},[_c('div',{staticClass:"text-left col col-sm-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-up")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("transactions.send")))])]),_vm._v(" "+_vm._s(item.cdebit)+": ")],1),_c('div',{staticClass:"text-right col col-sm-8"},[_vm._v(" "+_vm._s(item.debit + " " + item.currency)+" ")]),_c('div',{staticClass:"text-left col col-sm-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-down")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("transactions.receive")))])]),_vm._v(" "+_vm._s(item.ccredit)+": ")],1),_c('div',{staticClass:"text-right col col-sm-8"},[_vm._v(" "+_vm._s(item.credit + " " + item.currency)+" ")])])}),_c('br'),_vm._l((_vm.dashboardData.fees.last_month),function(item,index){return _c('div',{key:'f' + index,staticClass:"row"},[_c('div',{staticClass:"text-left col col-sm-4"},[_vm._v(" "+_vm._s(_vm.$t("transactions.fees"))+": ")]),_c('div',{staticClass:"text-right col col-sm-8"},[_vm._v(" "+_vm._s(item.debit + " " + item.currency)+" ")])])})],2)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('base-material-card',{staticClass:"dashboard-transactions",attrs:{"color":"orange","icon":"mdi-bank-transfer","title":_vm.$t('transactions.Transactions last 24h')}},[_c('v-card-text',[_vm._l((_vm.dashboardData.transactions.last_24h),function(item){return _c('div',{key:item.name,staticClass:"row"},[_c('div',{staticClass:"text-left col col-sm-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-up")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("transactions.send")))])]),_vm._v(" "+_vm._s(item.cdebit)+": ")],1),_c('div',{staticClass:"text-right col col-sm-8"},[_vm._v(" "+_vm._s(item.debit + " " + item.currency)+" ")]),_c('div',{staticClass:"text-left col col-sm-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-down")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("transactions.receive")))])]),_vm._v(" "+_vm._s(item.ccredit)+": ")],1),_c('div',{staticClass:"text-right col col-sm-8"},[_vm._v(" "+_vm._s(item.credit + " " + item.currency)+" ")])])}),_c('br'),_vm._l((_vm.dashboardData.fees.last_24h),function(item,index){return _c('div',{key:'f' + index,staticClass:"row"},[_c('div',{staticClass:"text-left col col-sm-4"},[_vm._v(" "+_vm._s(_vm.$t("transactions.fees"))+": ")]),_c('div',{staticClass:"text-right col col-sm-8"},[_vm._v(" "+_vm._s(item.debit + " " + item.currency)+" ")])])})],2)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('base-material-card',{staticClass:"dashboard-balance",attrs:{"color":"cyan","icon":"mdi-scale-balance","title":_vm.$t('user.Total Balances')}},[_c('v-card-text',_vm._l((_vm.dashboardData.balances),function(item,index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"text-left col col-sm-4"},[_vm._v(_vm._s(index)+":")]),_c('div',{staticClass:"text-right col col-sm-8"},[_vm._v(_vm._s(item))])])}),0)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }